import '@aws-amplify/ui-react/styles.css'

import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react'
import { Amplify } from 'aws-amplify'
import { Route, Routes } from 'react-router-dom'

import { awsConfig } from './awsConfig'
import { Mock } from './components'
import { PatientCareTeamProvider } from './providers/CareTeam/PatientCareTeam.provider'
import { DrinkCounterProvider } from './providers/DrinkCounter/DrinkCounterProvider'
import { MUMProvider } from './providers/MUM/MUMProvider'
import { useNavigation } from './providers/Navigation/Navigation.provider'
import { RequireAuth } from './providers/Navigation/RequireAuth.Wrapper'
import { OptionsProvider } from './providers/Options/Options.provider'
import { BACProvider } from './providers/PatientChartPage/BACProvider'
import { PatientChartPageProvider } from './providers/PatientChartPage/PatientChartPage.provider'
import { PatientsProvider } from './providers/Patients/Patients.provider'
import { PeerGroupsProvider } from './providers/PeerGroups/PeerGroups.provider'
import { ProviderDetailsProvider } from './providers/ProviderDetails/ProviderDetails.provider'
import { ScheduleProvider } from './providers/Schedule/Schedule.provider'
import { UserProvider } from './providers/User/User.provider'
import { Auth, Logout } from './views/Auth/Auth.container'
import { Main } from './views/Main/Main.container'
Amplify.configure(awsConfig)

// TODO ERROR AND LOADING STATES
export const App = () => {
  const { navigation } = useNavigation()

  return (
    <AmplifyProvider>
      <Authenticator.Provider>
        <UserProvider>
          <ScheduleProvider>
            <PatientCareTeamProvider>
              <ProviderDetailsProvider>
                <PatientChartPageProvider>
                  <BACProvider>
                    <DrinkCounterProvider>
                      <MUMProvider>
                        <OptionsProvider>
                          <PatientsProvider>
                            <PeerGroupsProvider>
                              <Routes>
                                <Route path={'/'} element={<Auth />}></Route>
                                <Route
                                  path={'/logout'}
                                  element={<Logout />}
                                ></Route>
                                {navigation!.allRoutes
                                  .filter((page) => !page.restricted)
                                  .map(
                                    ({
                                      route,
                                      View,
                                      useViewPaper,
                                      Provider,
                                    }) => (
                                      <Route
                                        path={`/${route}`}
                                        element={
                                          <RequireAuth>
                                            <Main usePaper={useViewPaper}>
                                              {View ? (
                                                Provider ? (
                                                  <Provider>{View}</Provider>
                                                ) : (
                                                  View
                                                )
                                              ) : (
                                                <Mock route={route} />
                                              )}
                                            </Main>
                                          </RequireAuth>
                                        }
                                        key={route}
                                      />
                                    )
                                  )}
                              </Routes>
                            </PeerGroupsProvider>
                          </PatientsProvider>
                        </OptionsProvider>
                      </MUMProvider>
                    </DrinkCounterProvider>
                  </BACProvider>
                </PatientChartPageProvider>
              </ProviderDetailsProvider>
            </PatientCareTeamProvider>
          </ScheduleProvider>
        </UserProvider>
      </Authenticator.Provider>
    </AmplifyProvider>
  )
}
